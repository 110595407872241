import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function VimPage() {
  const location = useLocation();

  return (
    <div className="flex min-h-screen">
      {/* Left menu with all project names */}
      <aside className="w-1/5 bg-gray-900 text-white h-screen p-6">
        <h2 className="text-xl font-bold mb-10">Project Names</h2>
        <ul className="space-y-6">
          <li className={`p-4 rounded-lg ${location.pathname === '/general-contractor' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/general-contractor">General Contractor</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/eng' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/eng">Extracting Submittals from Specs</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/vim' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/vim">VIM</Link>
          </li>
        </ul>
      </aside>

      {/* Project specific content */}
      <main className="w-4/5 p-10">
        <h2 className="text-2xl font-bold">VIM Project Page</h2>
        <p className="mt-6">This is the project-specific page for VIM.</p>
      </main>
    </div>
  );
}

export default VimPage;
