import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaFileAlt, FaCogs } from 'react-icons/fa';

function LandingPage() {
  const [activeProject, setActiveProject] = useState<string>('general-contractor');

  return (
    
    <div className="App flex justify-top items-center min-h-screen bg-gradient-to-r from-gray-100 to-blue-100">
      {/* Central Rectangular Box */}
      <aside className="w-1/3 bg-gray-900 text-white h-3/4 p-6 flex flex-col justify-between rounded-md shadow-lg">
        <div>
          <h2 className="cbase-title font-bold mb-10 text-center">cbase ai</h2>
          <nav>
            <Link
              to="/projects"
              className={`flex items-center p-4 rounded-lg shadow-md ${activeProject === 'projects' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}
              onClick={() => setActiveProject('projects')}
            >
              <FaCogs className="mr-3" />
              <span>Click here for your Projects</span>
            </Link>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default LandingPage;