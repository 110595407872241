import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function ProjectsPage() {
  const location = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const [processing, setProcessing] = useState<string | null>(null);
  const [processedFiles, setProcessedFiles] = useState<string[]>([]);
  const [showChatbot, setShowChatbot] = useState(false);

  // Handle multiple file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  // Handle file processing
  const handleProcessFile = (fileName: string) => {
    setProcessing(fileName);
    // Simulate file processing with a timeout
    setTimeout(() => {
      setProcessedFiles((prevProcessedFiles) => [...prevProcessedFiles, fileName]);
      setProcessing(null);  // Stop the loader after processing
    }, 2000);  // Simulate a 2-second process time
  };

  const handleChatbotClick = () => {
    setShowChatbot(true);
  };

  return (
    <div className="flex min-h-screen">
      {/* Left menu with all project names */}
      <aside className="w-1/5 bg-gray-900 text-white h-screen p-6">
        <h2 className="text-xl font-bold mb-10">Project Names</h2>
        <ul className="space-y-6">
          <li className={`p-4 rounded-lg ${location.pathname === '/general-contractor' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/general-contractor">General Contractor</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/eng' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/eng">Extracting Submittals from Specs</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/vim' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/vim">VIM</Link>
          </li>
        </ul>
      </aside>

      {/* Project specific content */}
      <main className="w-4/5 p-10">
        <h2 className="text-2xl font-bold">Extracting Submittals from Specifications</h2>
        <p className="mt-6">This is the project-specific page for ENG - Extracting Submittals from Specs.</p>

        {/* File upload section */}
        <div className="mt-10">
          <label className="block text-lg mb-2 inline">Upload Specification Files:</label>
          <input
            id="spec-files"
            type="file"
            accept=".pdf,.docx"
            multiple
            className="ml-4"
            onChange={handleFileUpload}
          />
        </div>

        {/* List of uploaded files with download and process button */}
        {files.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-bold mb-4">Uploaded Files:</h3>
            <ul>
              {files.map((file, index) => (
                <li key={index} className="mb-4 flex items-center">
                  <a
                    href={URL.createObjectURL(file)}
                    download={file.name}
                    className="text-blue-500 underline mr-4"
                  >
                    {file.name}
                  </a>
                  {processing === file.name ? (
                    <span>Processing...</span>  // Show loader while processing
                  ) : (
                    <button
                      className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 mr-4"
                      onClick={() => handleProcessFile(file.name)}
                    >
                      Process to Extract Insights
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Submittal lists section */}
        {processedFiles.length > 0 && (
          <div className="mt-10">
            <h3 className="text-lg font-bold mb-4">Submittal Lists:</h3>
            <ul>
              {processedFiles.map((fileName, index) => (
                <li key={index} className="mb-4">
                  <a
                    href={`/downloads/${fileName}`}  // Assuming processed file download link
                    className="text-blue-500 underline"
                  >
                    Download Processed Submittal - {fileName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Show the chatbot button after processing at least one file */}
        {processedFiles.length > 0 && (
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 mt-6"
            onClick={handleChatbotClick}
          >
            Open Chatbot
          </button>
        )}

        {/* Chatbot interface */}
        {showChatbot && (
          <div className="mt-8 bg-gray-100 p-6 rounded shadow-md">
            <h3 className="text-xl font-bold mb-4">Chat Interface</h3>
            {/* Simple chat UI - can be extended as needed */}
            <div className="chat-box bg-white p-4 rounded shadow h-64 overflow-y-auto">
              <p className="text-gray-700">Chatbot: How can I assist you with the submittals?</p>
            </div>
            <input
              type="text"
              className="mt-4 w-full p-2 border rounded"
              placeholder="Type your message..."
            />
          </div>
        )}
      </main>
    </div>
  );
}

export default ProjectsPage;
